import "./navbar.css";
import logo from "../assets/mazoto.png";
import React from "react";

function Navbar() {
  return (
    <header className="header">
      <div className="headerWrapper">
      <a href="#head"><img src={logo} className="logo" alt="logo"/></a>
      <nav className="nav">
        <a
          href="#about"
          className="link"
          rel="noopener noreferrer"
        >
          O que é
        </a>
        <a
          href="#who-we-are"
          className="link"
          rel="noopener noreferrer"
        >
          Quem Somos
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5511912604740&text=Olá%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20o%20Assistente%20Mazoto"
          className="link"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: "#158CD8" }}
        >
          Contate-nos
        </a>
      </nav>
      </div>
    </header>
  );
}

export default Navbar;
