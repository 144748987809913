import "./aboutAssistantMazoto.css";
import React from "react";
import CardAbout from "../../components/card";
import { Content, Title } from "../../components/texts";
import Grid from '@mui/material/Grid2';
import { Stack, Divider } from "@mui/material";

function AboutAssistantMazoto() {
  return (
    <section className="aboutAssistantMain" id="about">
      <Stack 
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem />}
        sx={{textAlign:"center", marginTop:"2em"}}
        >
        <Title>O que é o Assistente Mazoto</Title>
        <Content style={{ maxWidth: "800px", padding: "0px 15px 0px 15px" }}>
          O nosso assistente virtual está aqui para lhe auxiliar a gerenciar
          seu estabelecimento e ajudar a atender seus clientes realizando
          tarefas como:
        </Content>
      </Stack>
      <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 12 }} sx={{ width: "clamp(300px, 80%, 1200px)", marginBottom:"2em" }}>
        <Grid size={4}>
          <CardAbout title="Agendando horários">
            Consulta sua agenda e mostra horários disponíveis para o cliente agendar
          </CardAbout>
        </Grid>
        <Grid size={4}>
          <CardAbout title="Confirmando atendimentos">
            Próximo a data de atendimento, notifica o cliente do agendamento e confirma seu serviço
          </CardAbout>
        </Grid>
        <Grid size={4}>
          <CardAbout title="Informando clientes">
            Informa o cliente sobre horários de atendimento, diferentes serviços, valores e contato
          </CardAbout>
        </Grid>
      </Grid>
    </section>
  );
}
export default AboutAssistantMazoto;
