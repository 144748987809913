import "./App.css";
import Navbar from "./components/navbar";
import HeadContent from "./pages/landing/headContent";
import AboutAssistantMazoto from "./pages/landing/aboutAssistantMazoto";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WhoWeAre from "./pages/landing/whoWeAre";
import Footer from "./components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#92E3A9"
    },
    secondary: {
      main: "#B1D5EC"
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="main">
        <Navbar/>
        <HeadContent />
        <AboutAssistantMazoto />
        <WhoWeAre/>
        <Footer/>
      </div>
    </ThemeProvider>
  );
}

export default App;
