import React from "react";
import "./card.css";
import { Card, CardContent } from "@mui/material";
import { Content, SmallTitle } from "./texts";

export default function CardAbout(props) {
  return (
    <Card>
      <CardContent className="card">
        <SmallTitle>{props.title}</SmallTitle>
        <Content>{props.children}</Content>
      </CardContent>
    </Card>
  );
}
