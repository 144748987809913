import { Typography } from "@mui/material";

const HeaderText = (props) => (
  <Typography
    variant="h1"
    sx={{
      fontSize: "4rem",
      fontWeight: "bold",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Title = (props) => (
  <Typography
    variant="h2"
    sx={{
      fontSize: "3rem",
      marginTop: "2em",
      fontWeight: "bold",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const SmallTitle = (props) => (
  <Typography
    variant="h2"
    sx={{
      fontWeight: "bold",
      fontSize: "1.2rem",
      marginTop: "1em",
      color: "#333",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Subtitle = (props) => (
  <Typography
    variant="subtitle1"
    sx={{
      fontSize: "1.5rem",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Content = (props) => (
  <Typography
    variant="body1"
    sx={{
      fontSize: "1rem",
      width: "100%",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const SmallText = (props) => (
  <Typography
    variant="body2"
    sx={{
      fontSize: "0.75rem",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

export {
  Title,
  SmallTitle,
  Content,
  Subtitle,
  SmallText,
  HeaderText
};
