import React from "react";
import "./headContent.css";
import botImage from "../../assets/chatbot.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from "@mui/material";
import { HeaderText, Subtitle } from "../../components/texts";

function HeadContent() {
  return (
    <section className="head" id="head">
      <div className="headWrapper">
        <div className="content">
          <HeaderText>Assistente Mazoto</HeaderText>
          <Subtitle>
            O assistente que te ajudará com sua rotina em seu estabelecimento e
            a atender seus clientes da melhor forma.
          </Subtitle>

          <Button variant="contained" sx={{marginTop: "2em", padding: "1em", fontSize: "1.2rem", fontWeight: "bold", textTransform: "none"}} startIcon={<WhatsAppIcon/>} href="https://api.whatsapp.com/send?phone=5511912604740&text=Olá%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20o%20Assistente%20Mazoto" target="_blank">Quero no meu estabelecimento</Button>
        </div>
        <div className="image-container">
          <img src={botImage} alt="Assistente Mazoto" className="img" />
        </div>
      </div>
    </section>
  );
}

export default HeadContent;
